<template>
    <FormWrapper :is-loading="isLoading">
        <PageTitle title="Release Notes" slot="title" link="/helpContent/Release Notes" />
        <br />
        <div>
            <div id="release-heading">
                <table cellpadding="0" cellspacing="0" width="100%">
                    <tbody>
                        <tr align="left" style="width: 100%">
                            <td style="padding-left: 5px;">
                                <span id="release_date">{{ noteCreatedDate }}</span>
                            </td>
                            <td align="right" style="padding-right: 5px;">
                                <span id="version_name" class="div_versionName">{{ noteVersion }}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    <div style="padding-left:30px;padding-right: 30px;">
        <b><div v-html="noteTitle" style="margin-top: 30px;"> </div></b>
        <div v-html="noteContent" id="note-detail" style="margin-top:20px;margin-bottom: 40px;"></div>
    
    </div>
        
        <FormRow>
            <div class="col-sm-6">
                <ButtonGroup>
                    <FormButton type="info" @click="()=>{$router.go(-1)}">Back</FormButton>
                </ButtonGroup>
            </div>
        </FormRow>
    </FormWrapper>
</template>

<script>
    import FormWrapper from 'Components/form/FormWrapper';
    import PageTitle from 'Components/layout/PageTitle';
    import DateUtil from 'Utils/dateUtil';
    import loadingMixin from 'Mixins/loadingMixin';
    import {getReleaseNotesDetails} from '../api';
    export default {
        name: "Detail",
        mixins: [loadingMixin],
        components: {
            FormWrapper,
            PageTitle
        },
        data() {
            return {
                noteContent: "",
                noteTitle: "",
                noteVersion: "",
                noteCreatedDate: ""
            }
        },
        computed: {
          userData() {
              return this.$store.state.user.user
            }
        },
        watch: {
        },
        created() {
            this.getData()
        },
        methods: {
            getData() {
                setTimeout(() => {
                    this.showLoader();
                    const data = {
                        releaseId: (this.$route.params.id)?atob(this.$route.params.id):null
                    };
                    getReleaseNotesDetails(data)
                        .then(this.handleResponse)
                        .catch(this.handleError)
                },
                50
                )
            },
            handleResponse(response) {
                this.hideLoader();
                this.noteContent = response.data[0].Notes;
                this.noteTitle = response.data[0].ReleaseTitle;
                this.noteVersion = response.data[0].VersionName;
                this.noteCreatedDate = DateUtil.formatDateWithSlash(response.data[0].ReleaseDate);
            },
        }
    }
</script>
<style lang="scss">
    #release-heading{
        background-color: #eeedf2;
        height: 21px;
        font: white;
        color: black;
        padding-top: 4px;
        font-weight: bold;
        width: 100%;
        vertical-align: middle;
    }

    ol{
        padding-left: 5%;
    }
</style>